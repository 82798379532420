import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/css/App.css";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/Store";
import { Provider } from "react-redux";
import App from "./App";
import i18n from "./config/i18next";
import { I18nextProvider } from "react-i18next";
import { Theme } from "./config/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={Theme}>
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: async (registration) => {
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  },
});
