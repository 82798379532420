import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  DeepPartial,
  Store,
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { TUserState } from "../types/User";
import { usersReducer } from "./reducers/UserReducer";
import { requestReducer } from "./reducers/RequestReducer";
import { ErrorHandler } from "./middlewares/ErrorHandler";
import { TAuthState } from "../types/Auth";
import { authReducer } from "./reducers/AuthReducer";
import { TErrorState } from "../types/Error";
import { errorReducer } from "./reducers/ErrorReducer";
import { TMenuState } from "../types/Menu";
import { menuReducer } from "./reducers/MenuReducer";
import { TContentState } from "../types/ContentPage";
import { contentReducer } from "./reducers/ContentReducer";

export interface IAppState {
  userState: TUserState;
  authState: TAuthState;
  errorState: TErrorState;
  menuState: TMenuState;
  contentState: TContentState;
  appState: any;
}

const initialAppState: DeepPartial<IAppState> = {
  userState: undefined,
  authState: undefined,
  errorState: undefined,
  menuState: undefined,
  contentState: undefined,
  appState: {},
};

const rootReducer = combineReducers<IAppState>({
  userState: usersReducer,
  appState: requestReducer,
  authState: authReducer,
  errorState: errorReducer,
  menuState: menuReducer,
  contentState: contentReducer,
});

export default function configureStore(): Store<IAppState, AnyAction> {
  return createStore(
    rootReducer,
    initialAppState,
    composeWithDevTools(applyMiddleware(thunk, ErrorHandler))
  );
}
