import * as React from "react";
import crisisMenuIcon from "../assets/images/icon-crisis-menu.svg";
import Fab from "@material-ui/core/Fab";
import { useDispatch } from "react-redux";
import { useStoreState } from "../hooks/useStoreState";
import { setSubMenuOpen } from "../store/actions/MenuActions";
import { MenuType } from "../types/Menu";

const CrisisMenuButton: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const menuState = useStoreState().menuState;

  const setCrisisMenuOpen = () => {
    if (menuState.crisisMenu) {
      dispatch(
        setSubMenuOpen(
          menuState.activeSubMenu && menuState.activeSubMenu.length > 0
            ? []
            : menuState.crisisMenu,
          MenuType.CRISIS
        )
      );
    }
  };

  return (
    <Fab
      color="primary"
      aria-label="add"
      style={{
        position: "fixed",
        bottom: "10px",
        right: "10px",
        padding: "10px",
        zIndex: 1400,
      }}
      onClick={setCrisisMenuOpen}
    >
      <img src={crisisMenuIcon} alt="" />
    </Fab>
  );
};

export default CrisisMenuButton;
