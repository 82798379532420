export type TErrorState = {
  readonly message?: string;
  readonly code?: number;
};

export const initialErrorState: TErrorState = {
  message: undefined,
  code: undefined,
};

export type TApiErrorEntity = {
  [key: string]: Array<string>;
};

export enum SetAppError {
  REQUEST = "SET_APP_ERROR_REQUEST",
  SUCCESS = "SET_APP_ERROR_SUCCESS",
  FAILED = "SET_APP_ERROR_FAILED",
}

export type TSetAppErrorAction =
  | {
      type: SetAppError.REQUEST;
    }
  | {
      type: SetAppError.SUCCESS;
      payload: { code: number; message: string };
    }
  | {
      type: SetAppError.FAILED;
    };

export type TErrorActions = TSetAppErrorAction;
