import * as React from "react";
import SimplePage from "./item-types/SimplePage";
import { ApiMenuEntityCode, TApiMenuEntity } from "../../types/Menu";
import arrow_right from "../../assets/images/icon_arrow_right.svg";
import phoneIcon from "../../assets/images/icon-phone.svg";
import PhoneLink from "./item-types/PhoneLink";
import HolderItem from "./item-types/HolderItem";
import MotivationalLink from "./item-types/MotivationalLink";
import ProfileLink from "./item-types/ProfileLink";
import NotesLink from "./item-types/NotesLink";
import UrlLink from "./item-types/UrlLink";

interface INavbarItemProps {
  menuItem: TApiMenuEntity;
  closeMenu?: () => void;
}

const NavbarItem: React.FC<INavbarItemProps> = ({
  menuItem: { title, reference, code, url, items },
  closeMenu,
}): JSX.Element => {
  switch (code) {
    case ApiMenuEntityCode.PHONE:
      return (
        <PhoneLink
          url={url || ""}
          closeMenuOnClick={closeMenu}
          icon={phoneIcon}
          title={title}
        />
      );

    case ApiMenuEntityCode.HOLDER:
      return (
        <HolderItem
          closeMenuOnClick={closeMenu}
          icon={arrow_right}
          title={title}
          subMenu={items}
        />
      );

    case ApiMenuEntityCode.MOTIVATIONAL_IMAGES:
      return (
        <MotivationalLink
          icon={arrow_right}
          closeMenuOnClick={closeMenu}
          title={title}
        />
      );

    case ApiMenuEntityCode.PROFILE:
      return (
        <ProfileLink
          icon={arrow_right}
          closeMenuOnClick={closeMenu}
          title={title}
        />
      );

    case ApiMenuEntityCode.NOTES:
      return (
        <NotesLink
          icon={arrow_right}
          closeMenuOnClick={closeMenu}
          title={title}
        />
      );

    case ApiMenuEntityCode.SIMPLE:
      return (
        <SimplePage
          reference={reference}
          closeMenuOnClick={closeMenu}
          icon={arrow_right}
          title={title}
        />
      );

    default:
      return (
        <UrlLink
          url={url || ""}
          closeMenuOnClick={closeMenu}
          icon={arrow_right}
          title={title}
        />
      );
  }
};

export default NavbarItem;
