import * as React from "react";
import logo_big from "../assets/images/logo_big.svg";
import { Grid } from "@material-ui/core";
import { useLogoStyles } from "./Logo.styles";

const Logo: React.FC = (): JSX.Element => {
  const classes = useLogoStyles();

  return (
    <Grid className={classes.root} container justify={"center"}>
      <img src={logo_big} alt="logo" width={230} height={170} />
    </Grid>
  );
};

export default Logo;
