import { Reducer } from "redux";
import {
  ApiMenuEntityType,
  GetCrisisMenu,
  GetMainMenu,
  initialMenuState,
  SetMenuOpen,
  SetSubMenuOpen,
  TApiMenuEntity,
  TMenuActions,
  TMenuState,
} from "../../types/Menu";
import { filter, isEmpty } from "lodash";

export const menuReducer: Reducer<TMenuState, TMenuActions> = (
  state = initialMenuState,
  action
) => {
  switch (action.type) {
    case GetMainMenu.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetMainMenu.SUCCESS:
      return {
        ...state,
        loading: false,
        mainMenu: filter(action.payload.data, (menuItem: TApiMenuEntity) => {
          return (
            menuItem.type !== ApiMenuEntityType.HOLDER ||
            (menuItem.type === ApiMenuEntityType.HOLDER &&
              !isEmpty(menuItem.items))
          );
        }),
      };

    case GetMainMenu.FAILED:
      return {
        ...state,
        loading: false,
      };

    case GetCrisisMenu.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetCrisisMenu.SUCCESS:
      return {
        ...state,
        loading: false,
        crisisMenu: action.payload.data,
      };

    case GetCrisisMenu.FAILED:
      return {
        ...state,
        loading: false,
      };

    case SetMenuOpen.SET:
      return {
        ...state,
        navbarMenuOpen: action.payload,
      };

    case SetSubMenuOpen.SET:
      return {
        ...state,
        activeSubMenu: action.payload,
        activeMenuType: action.menuType,
      };

    default:
      return state;
  }
};
