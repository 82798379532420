import * as React from "react";
import { Container, Grid } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { useNavbarStyles } from "./Navbar.styles";
import { Skeleton } from "@material-ui/lab";

export interface INavbarSkeletonContentProps {
  relativeContent?: boolean;
}

const NavbarSkeleton: React.FC<INavbarSkeletonContentProps> = (
  props
): JSX.Element => {
  const classes = useNavbarStyles(props);
  const skeletons = [];

  for (let i = 0; i < 3; i++) {
    skeletons.push(
      <Grid item xs={12} key={i}>
        <Skeleton
          variant={"rect"}
          height={74}
          style={{ marginBottom: "15px", borderRadius: "10px" }}
        />
      </Grid>
    );
  }

  return (
    <div className={classes.contentRoot}>
      <Container maxWidth={"sm"}>
        <Fade in={true}>
          <Grid container>{skeletons}</Grid>
        </Fade>
      </Container>
    </div>
  );
};

export default NavbarSkeleton;
