import * as React from "react";
import pencilIcon from "../assets/images/icon-pencil.png";
import Fab from "@material-ui/core/Fab";
import { useStoreState } from "../hooks/useStoreState";
import SaveNoteDialog from "./dialogs/SaveNoteDialog";
import { useState } from "react";

const CopyNoteTextButton: React.FC = (): JSX.Element => {
  const userState = useStoreState().userState;
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <SaveNoteDialog
        open={openModal}
        noteText={userState.tempNoteText as string}
      />
      <Fab
        color="primary"
        aria-label="add"
        style={{
          position: "fixed",
          bottom: "80px",
          right: "10px",
          padding: "10px",
          zIndex: 1200,
          backgroundColor: "#E5F4D5",
        }}
        onClick={() => setOpenModal(true)}
      >
        <img src={pencilIcon} alt="" />
      </Fab>
    </>
  );
};

export default CopyNoteTextButton;
