import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useSubMenuStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      crisisMenuDialog: {
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
        "& .MuiPaper-root": {
          marginBottom: "70px",
        },
      },
    }),
  { index: 1 }
);
