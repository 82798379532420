import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import iconClose from "../../assets/images/icon_close.svg";
import { useState } from "react";
import { useDialogStyles } from "./Dialog.styles";
import { useStoreState } from "../../hooks/useStoreState";
import { useDispatch } from "react-redux";
import { closeMotivationalDialog } from "../../store/actions/UserActions";
import { useApp } from "../../hooks/useApp";
import { Skeleton } from "@material-ui/lab";
import { setMotivationalDialogShown } from "../../helpers/Misc";

const RandomMotivationalDialog: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const classes = useDialogStyles();
  const userState = useStoreState().userState;
  const { app } = useApp();

  const onClose = () => {
    setOpen(false);
    dispatch(closeMotivationalDialog(true));
    setMotivationalDialogShown();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="motivational-dialog-title"
      aria-describedby="motivational-dialog-description"
    >
      <DialogTitle id="motivational-dialog-title">
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={() => onClose()}
        >
          <img src={iconClose} alt="" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div id="motivational-dialog-description">
          {app.isLoading() ? (
            <>
              <Skeleton
                variant={"rect"}
                height={30}
                style={{ marginBottom: "15px" }}
              />
              <Skeleton width={"100%"} variant={"rect"} height={250} />
            </>
          ) : (
            <>
              <div className={classes.moodTitle}>
                {userState.activeRandomPopup?.title}
              </div>
              <div
                className={classes.content}
                dangerouslySetInnerHTML={{
                  __html: userState.activeRandomPopup?.content || "",
                }}
              />
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RandomMotivationalDialog;
