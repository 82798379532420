import * as React from "react";
import "./assets/styles/css/App.css";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import AppRoutes from "./containers/routes/AppRoutes";
import { Container, CssBaseline } from "@material-ui/core";
import Loader from "./components/Loader";
import { useApp } from "./hooks/useApp";
import ErrorPage from "./containers/ErrorPage";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useStoreState } from "./hooks/useStoreState";
import { useEffect } from "react";

const App: React.FC = () => {
  const { app } = useApp();
  const { t } = useTranslation();
  const errorState = useStoreState().errorState;
  const menuState = useStoreState().menuState;

  useEffect(() => {
    if (menuState.navbarMenuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [menuState.navbarMenuOpen]);

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorPage message={t("internal.server.error")} />}
    >
      <ErrorBoundary>
        <BrowserRouter>
          <CssBaseline />
          {app.isLoading() && <Loader />}
          <Container maxWidth={"sm"}>
            {errorState.message ? (
              <ErrorPage message={errorState.message} />
            ) : (
              <AppRoutes />
            )}
          </Container>
        </BrowserRouter>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  );
};

export default App;
