import { AxiosResponse } from "axios";

export interface TApiUserEntity {
  activated_at: Date;
  created_at: Date;
  created_ip_address: null | string;
  crisis_person_name: null | string;
  crisis_person_phone: null | string;
  deleted_at: null | Date;
  email: string;
  id: number;
  is_activated: boolean;
  is_guest: number;
  is_superuser: number;
  last_gambled: Date;
  last_ip_address: null | string;
  last_login: null | Date;
  last_seen: null | Date;
  money_spend_per_period: null | number;
  money_spend_period: "week" | "month";
  name: null | string;
  permissions: null;
  surname: null | string;
  updated_at: Date;
  username: string;
}

export type TRandomPopupApiEntity = {
  id: number;
  title: string;
  content: string;
  show_date: Date | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
};

export type TMotivatingImageApiEntity = {
  created_at: Date;
  deleted_at: null | Date;
  id: number;
  image: TImageApiEntity;
  sort_order: number;
  text: null | string;
  updated_at: Date;
  user_id: number;
};

export type TMyNoteApiEntity = {
  created_at: Date;
  id: number;
  parent_url: string;
  text: string;
  updated_at: Date;
  user_id: number;
};

export type TImageApiEntity = {
  content_type: string;
  created_at: Date;
  description: null | string;
  disk_name: string;
  extension: string;
  field: string;
  file_name: string;
  file_size: number;
  id: number;
  path: string;
  sort_order: number;
  title: null | string;
  updated_at: Date;
};

export interface TUserState {
  readonly data?: TApiUserEntity;
  readonly badMoodReference?: string;
  readonly activeRandomPopup?: TRandomPopupApiEntity;
  readonly tempNoteText?: string;
  readonly noteSaved?: boolean;
  readonly loading?: boolean;
  readonly motivatingImages?: Array<TMotivatingImageApiEntity>;
  readonly myNotes?: Array<TMyNoteApiEntity>;
}

export const initialUserState: TUserState = {
  data: undefined,
};

export enum GetCurrentUser {
  REQUEST = "GET_CURRENT_USER_REQUEST",
  SUCCESS = "GET_CURRENT_USER_SUCCESS",
  FAILED = "GET_CURRENT_USER_FAILED",
}

export type TGetCurrentUserAction =
  | {
      type: GetCurrentUser.REQUEST;
    }
  | {
      type: GetCurrentUser.SUCCESS;
      payload: AxiosResponse<{ user: TApiUserEntity }>;
    }
  | {
      type: GetCurrentUser.FAILED;
      payload: AxiosResponse<{ user: TApiUserEntity }>;
    };

export enum UpdateUserProfile {
  REQUEST = "UPDATE_USER_PROFILE_REQUEST",
  SUCCESS = "UPDATE_USER_PROFILE_SUCCESS",
  FAILED = "UPDATE_USER_PROFILE_FAILED",
}

export type TUpdateUserProfileAction =
  | {
      type: UpdateUserProfile.REQUEST;
    }
  | {
      type: UpdateUserProfile.SUCCESS;
      payload: AxiosResponse<TApiUserEntity>;
    }
  | {
      type: UpdateUserProfile.FAILED;
      payload: AxiosResponse<TApiUserEntity>;
    };

export enum SetMood {
  REQUEST = "SET_MOOD_REQUEST",
  SUCCESS = "SET_MOOD_SUCCESS",
  FAILED = "SET_MOOD_FAILED",
}

export type TSetMoodAction =
  | {
      type: SetMood.REQUEST;
    }
  | {
      type: SetMood.SUCCESS;
      payload: AxiosResponse<{
        "redirect-reference"?: string;
      }>;
    }
  | {
      type: SetMood.FAILED;
      payload: AxiosResponse<{
        "redirect-reference"?: string;
      }>;
    };

export enum GetRandomPopup {
  REQUEST = "GET_RANDOM_POPUP_REQUEST",
  SUCCESS = "GET_RANDOM_POPUP_SUCCESS",
  FAILED = "GET_RANDOM_POPUP_FAILED",
}

export type TGetRandomPopupAction =
  | {
      type: GetRandomPopup.REQUEST;
    }
  | {
      type: GetRandomPopup.SUCCESS;
      payload: AxiosResponse<TRandomPopupApiEntity>;
    }
  | {
      type: GetRandomPopup.FAILED;
      payload: AxiosResponse<TRandomPopupApiEntity>;
    };

export enum SaveMyNote {
  REQUEST = "SAVE_MY_NOTE_REQUEST",
  SUCCESS = "SAVE_MY_NOTE_SUCCESS",
  FAILED = "SAVE_MY_NOTE_FAILED",
}

export type TSaveMyNoteAction =
  | {
      type: SaveMyNote.REQUEST;
    }
  | {
      type: SaveMyNote.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: SaveMyNote.FAILED;
      payload: AxiosResponse<any>;
    };

export enum UploadMotivationalImage {
  REQUEST = "UPLOAD_MOTIVATIONAL_IMAGE_REQUEST",
  SUCCESS = "UPLOAD_MOTIVATIONAL_IMAGE_SUCCESS",
  FAILED = "UPLOAD_MOTIVATIONAL_IMAGE_FAILED",
}

export type TUploadMotivationalImageAction =
  | {
      type: UploadMotivationalImage.REQUEST;
    }
  | {
      type: UploadMotivationalImage.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: UploadMotivationalImage.FAILED;
      payload: AxiosResponse<any>;
    };

export enum GetMotivatingImages {
  REQUEST = "GET_MOTIVATING_IMAGES_REQUEST",
  SUCCESS = "GET_MOTIVATING_IMAGES_SUCCESS",
  FAILED = "GET_MOTIVATING_IMAGES_FAILED",
}

export type TGetMotivatingImagesAction =
  | {
      type: GetMotivatingImages.REQUEST;
    }
  | {
      type: GetMotivatingImages.SUCCESS;
      payload: AxiosResponse<Array<TMotivatingImageApiEntity>>;
    }
  | {
      type: GetMotivatingImages.FAILED;
      payload: AxiosResponse<Array<TMotivatingImageApiEntity>>;
    };

export enum GetMyNotes {
  REQUEST = "GET_MY_NOTES_REQUEST",
  SUCCESS = "GET_MY_NOTES_SUCCESS",
  FAILED = "GET_MY_NOTES_FAILED",
}

export type TGetMyNotesAction =
  | {
      type: GetMyNotes.REQUEST;
    }
  | {
      type: GetMyNotes.SUCCESS;
      payload: AxiosResponse<Array<TMyNoteApiEntity>>;
    }
  | {
      type: GetMyNotes.FAILED;
      payload: AxiosResponse<any>;
    };

export enum DeleteMyNote {
  REQUEST = "DELETE_MY_NOTE_REQUEST",
  SUCCESS = "DELETE_MY_NOTE_SUCCESS",
  FAILED = "DELETE_MY_NOTE_FAILED",
}

export type TDeleteMyNoteAction =
  | {
      type: DeleteMyNote.REQUEST;
    }
  | {
      type: DeleteMyNote.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: DeleteMyNote.FAILED;
      payload: AxiosResponse<any>;
    };

export enum DeleteMotivatingImage {
  REQUEST = "DELETE_MOTIVATING_IMAGE_REQUEST",
  SUCCESS = "DELETE_MOTIVATING_IMAGE_SUCCESS",
  FAILED = "DELETE_MOTIVATING_IMAGE_FAILED",
}

export type TDeleteMotivatingImageAction =
  | {
      type: DeleteMotivatingImage.REQUEST;
    }
  | {
      type: DeleteMotivatingImage.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: DeleteMotivatingImage.FAILED;
      payload: AxiosResponse<any>;
    };

export enum CloseMotivationalDialog {
  SET = "SET_CLOSE_MOTIVATIONAL_DIALOG",
}

export type TCloseMotivationalDialogAction = {
  type: CloseMotivationalDialog.SET;
  payload: boolean;
};

export enum CopyNoteText {
  SET = "COPY_NOTE_TEXT",
}

export type TCopyNoteTextAction = {
  type: CopyNoteText.SET;
  payload: string;
};

export type TUserActions =
  | TGetCurrentUserAction
  | TSetMoodAction
  | TGetRandomPopupAction
  | TCloseMotivationalDialogAction
  | TUpdateUserProfileAction
  | TCopyNoteTextAction
  | TSaveMyNoteAction
  | TUploadMotivationalImageAction
  | TGetMotivatingImagesAction
  | TDeleteMotivatingImageAction
  | TGetMyNotesAction
  | TDeleteMyNoteAction;
