import * as React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Routes } from "../../Routes";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";

const AppRoutes: React.FC = (): JSX.Element => {
  return (
    <Switch>
      {Object.entries(Routes).map(([key, route]) => {
        const {
          path,
          exact = false,
          redirect = null,
          component: Cmp,
          authorized = false,
        } = route;

        const redirectPath = redirect ? redirect(true) : "";

        if (redirect) {
          return <Redirect to={redirectPath} />;
        }

        if (authorized && !redirect) {
          return (
            <PrivateRoute
              key={key}
              path={path}
              exact={exact}
              component={Cmp as React.FC}
            />
          );
        }

        return (
          <AuthRoute
            key={key}
            path={path}
            exact={exact}
            component={Cmp as React.FC}
          />
        );
      })}
    </Switch>
  );
};

export default AppRoutes;
