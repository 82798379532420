import * as React from "react";
import { RouteType } from "./types/Route";
const HomePage = React.lazy(() => import("./containers/HomePage"));
const ProfilePage = React.lazy(() => import("./containers/ProfilePage"));
const ContentPage = React.lazy(() => import("./containers/ContentPage"));
const ErrorPage = React.lazy(() => import("./containers/ErrorPage"));
const MotivatingPage = React.lazy(() => import("./containers/MotivatingPage"));
const MyNotesPage = React.lazy(() => import("./containers/MyNotesPage"));
const LoginPage = React.lazy(() => import("./containers/auth/LoginPage"));
const RegisterPage = React.lazy(() => import("./containers/auth/RegisterPage"));
const ForgotPasswordPage = React.lazy(
  () => import("./containers/auth/ForgotPasswordPage")
);
const RegisterSuccessPage = React.lazy(
  () => import("./containers/auth/RegisterSuccessPage")
);
const ResetPasswordPage = React.lazy(
  () => import("./containers/auth/ResetPasswordPage")
);
const ResetPasswordSuccessPage = React.lazy(
  () => import("./containers/auth/ResetPasswordSuccessPage")
);

export const routes = {
  home: "/",

  //auth
  loginPage: "/auth/login",
  registerPage: "/auth/register",
  registerSuccessPage: "/auth/register/success",
  forgotPasswordPage: "/auth/forgot",
  resetPasswordPage: "/auth/reset",
  resetPasswordSuccessPage: "/auth/reset/success",

  //profile
  profilePage: "/profile",
  notes: "/notes",
  motivationalPage: "/motivation",
  myNotes: "/notes",

  //cms
  cmsPage: "/content/:reference",
};

export const Routes: RouteType = {
  home: {
    path: routes.home,
    exact: true,
    authorized: true,
    component: HomePage,
  },
  profilePage: {
    path: routes.profilePage,
    exact: true,
    authorized: true,
    component: ProfilePage,
  },
  cmsPage: {
    path: routes.cmsPage,
    exact: true,
    authorized: true,
    component: ContentPage,
  },
  motivationalPage: {
    path: routes.motivationalPage,
    exact: true,
    authorized: true,
    component: MotivatingPage,
  },
  myNotes: {
    path: routes.myNotes,
    exact: true,
    authorized: true,
    component: MyNotesPage,
  },
  loginPage: {
    path: routes.loginPage,
    exact: true,
    component: LoginPage,
  },
  registerPage: {
    path: routes.registerPage,
    exact: true,
    component: RegisterPage,
  },
  forgotPasswordPage: {
    path: routes.forgotPasswordPage,
    exact: true,
    component: ForgotPasswordPage,
  },
  registerSuccessPage: {
    path: routes.registerSuccessPage,
    exact: true,
    component: RegisterSuccessPage,
  },
  resetPasswordPage: {
    path: routes.resetPasswordPage,
    exact: true,
    component: ResetPasswordPage,
  },
  resetPasswordSuccessPage: {
    path: routes.resetPasswordSuccessPage,
    exact: true,
    component: ResetPasswordSuccessPage,
  },
  notFound: {
    path: "*",
    exact: false,
    component: ErrorPage,
  },
};
