import { apiCall } from "./BaseAction";
import {
  GetCrisisMenu,
  GetMainMenu,
  MenuType,
  SetMenuOpen,
  SetSubMenuOpen,
  TApiMenuEntity,
  TGetCrisisMenuAction,
  TGetMainMenuAction,
  TSetMenuOpenAction,
  TSetSubMenuOpenAction,
} from "../../types/Menu";
import { Dispatch } from "redux";

export const getMainMenu = () => {
  return apiCall<TGetMainMenuAction, null, TApiMenuEntity>(
    GetMainMenu,
    "GET",
    `/menu/${MenuType.MAIN}`,
    true
  );
};

export const getCrisisMenu = () => {
  return apiCall<TGetCrisisMenuAction, null, TApiMenuEntity>(
    GetCrisisMenu,
    "GET",
    `/menu/${MenuType.CRISIS}`,
    true
  );
};

export const setNavbarMenuOpen = (payload: boolean) => {
  return (dispatch: Dispatch<TSetMenuOpenAction>) => {
    dispatch({
      type: SetMenuOpen.SET,
      payload,
    });
  };
};

export const setSubMenuOpen = (
  payload: Array<TApiMenuEntity>,
  menuType?: MenuType
) => {
  return (dispatch: Dispatch<TSetSubMenuOpenAction>) => {
    dispatch({
      type: SetSubMenuOpen.SET,
      payload,
      menuType,
    });
  };
};
