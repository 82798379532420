import { Reducer } from "redux";
import {
  GetStaticPageContent,
  initialContentState,
  TContentActions,
  TContentState,
} from "../../types/ContentPage";

export const contentReducer: Reducer<TContentState, TContentActions> = (
  state = initialContentState,
  action
) => {
  switch (action.type) {
    case GetStaticPageContent.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetStaticPageContent.SUCCESS:
      return {
        ...state,
        loading: false,
        activePage: action.payload.data,
      };

    case GetStaticPageContent.FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
