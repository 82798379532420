import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useLogoStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        margin: "65px 0",
      },
    }),
  { index: 1 }
);
