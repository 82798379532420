import * as React from "react";
import { useNavbarStyles } from "./Navbar.styles";
import { Container, Grid } from "@material-ui/core";
import NavbarItem from "./NavbarItem";
import { useStoreState } from "../../hooks/useStoreState";
import Fade from "@material-ui/core/Fade";
import { useApp } from "../../hooks/useApp";
import NavbarSkeleton from "./NavbarSkeleton";
import { useDispatch } from "react-redux";
import { setNavbarMenuOpen } from "../../store/actions/MenuActions";

export interface INavbarContentProps {
  relativeContent?: boolean;
}

const NavbarContent: React.FC<INavbarContentProps> = (props): JSX.Element => {
  const classes = useNavbarStyles(props);
  const menuState = useStoreState().menuState;
  const dispatch = useDispatch();
  const { app } = useApp();

  if (app.isLoading()) {
    return <NavbarSkeleton relativeContent={props.relativeContent} />;
  }

  const closeMenu = () => {
    dispatch(setNavbarMenuOpen(false));
  };

  return (
    <div className={classes.contentRoot}>
      <Container maxWidth={"sm"}>
        <Fade in={true}>
          <Grid container>
            {menuState.mainMenu?.map((menuItem, index: number) => {
              return (
                <NavbarItem
                  closeMenu={closeMenu}
                  key={index}
                  menuItem={menuItem}
                />
              );
            })}
          </Grid>
        </Fade>
      </Container>
    </div>
  );
};

export default NavbarContent;
