import React, { useCallback, useEffect, Suspense } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useStoreState } from "../../hooks/useStoreState";
import { TDispatch } from "../../types/Thunk";
import { useDispatch } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { routes } from "../../Routes";
import {
  getAccessToken,
  removeAuthInfoFromLocalStorage,
  shouldShowMotivationalDialog,
} from "../../helpers/Misc";
import { useApp } from "../../hooks/useApp";
import {
  getCurrentUser,
  getRandomPopup,
} from "../../store/actions/UserActions";
import Navbar from "../../components/navigation/Navbar";
import { getCrisisMenu, getMainMenu } from "../../store/actions/MenuActions";
import MoodRatingDialog from "../../components/dialogs/MoodRatingDialog";
import RandomMotivationalDialog from "../../components/dialogs/RandomMotivationalDialog";
import CrisisMenuButton from "../../components/CrisisMenuButton";
import CopyNoteTextButton from "../../components/CopyNoteTextButton";

interface IPrivateRouteProps extends RouteProps {
  component: React.ComponentClass | React.FC;
}

const PrivateRoute: React.FC<IPrivateRouteProps & RouteComponentProps> = (
  props,
  ...rest
) => {
  const { app } = useApp();
  const dispatch = useDispatch<TDispatch>();
  const userState = useStoreState().userState;

  const getUser = useCallback(async () => {
    if (!userState.data && !app.isLoading() && !!getAccessToken()) {
      await dispatch(getCurrentUser());
      await dispatch(getMainMenu());
      await dispatch(getCrisisMenu());
    }
  }, [app, dispatch, userState.data]);

  useEffect(() => {
    if (!getAccessToken()) {
      return props.history.push(routes.loginPage);
    }
  }, [props.history]);

  getUser().catch(() => {
    removeAuthInfoFromLocalStorage();
    props.history.replace(routes.loginPage);
  });

  const dispatchRandomPopupAction = useCallback(async () => {
    await dispatch(getRandomPopup());
  }, [dispatch]);

  useEffect(() => {
    if (
      shouldShowMotivationalDialog() &&
      !userState.activeRandomPopup &&
      userState.data
    ) {
      dispatchRandomPopupAction();
    }
  }, [dispatchRandomPopupAction, userState.activeRandomPopup, userState.data]);

  return (
    <Suspense fallback={""}>
      <Navbar />
      {userState.data && (
        <>
          <MoodRatingDialog />
          {userState.activeRandomPopup && <RandomMotivationalDialog />}
        </>
      )}

      <main style={{ paddingBottom: "60px" }}>
        <Route component={props.component} exact path={props.path} {...rest} />
      </main>

      {userState.tempNoteText && <CopyNoteTextButton />}
      <CrisisMenuButton />
    </Suspense>
  );
};
export default withRouter(PrivateRoute);
