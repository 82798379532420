import * as React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../Routes";
import { Button } from "@material-ui/core";
import { useNavbarStyles } from "../Navbar.styles";

interface IMotivationalLinkProps {
  closeMenuOnClick?: () => void;
  icon: any;
  title: string;
}

const MotivationalLink: React.FC<IMotivationalLinkProps> = (
  props
): JSX.Element => {
  const classes = useNavbarStyles({});
  const { closeMenuOnClick, ...rest } = props;

  return (
    <Button
      {...rest}
      className={classes.menuItem}
      onClick={props.closeMenuOnClick}
      component={Link}
      to={routes.motivationalPage}
    >
      {props.title}
      <img src={props.icon} alt="arrow" />
    </Button>
  );
};

export default MotivationalLink;
