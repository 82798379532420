import * as React from "react";
import { createStyles, LinearProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: "4px",
    },
  })
);

const Loader: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <LinearProgress className={classes.root} variant={"indeterminate"} />
    </div>
  );
};

export default Loader;
