import * as React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import iconClose from "../../assets/images/icon_close.svg";
import { useDialogStyles } from "./Dialog.styles";
import { useTranslation } from "react-i18next";
import mood_1 from "../../assets/images/mood_1.svg";
import mood_2 from "../../assets/images/mood_2.svg";
import mood_3 from "../../assets/images/mood_3.svg";
import mood_4 from "../../assets/images/mood_4.svg";
import mood_5 from "../../assets/images/mood_5.svg";
import { useDispatch } from "react-redux";
import { setUserMood } from "../../store/actions/UserActions";
import { useStoreState } from "../../hooks/useStoreState";
import { RouteComponentProps, withRouter } from "react-router";
import { reverse } from "named-urls";
import { routes } from "../../Routes";
import { setMoodDialogShown, shouldShowMoodDialog } from "../../helpers/Misc";

const MoodRatingDialog: React.FC<RouteComponentProps<any>> = ({
  history,
}): JSX.Element => {
  const [open, setOpen] = useState(shouldShowMoodDialog());
  const dispatch = useDispatch();
  const classes = useDialogStyles();
  const { t } = useTranslation();
  const moods = [mood_1, mood_2, mood_3, mood_4, mood_5];
  const userState = useStoreState().userState;

  useEffect(() => {
    if (userState.badMoodReference) {
      history.push(
        reverse(routes.cmsPage, {
          reference: userState.badMoodReference,
        })
      );
    }
  }, [userState.badMoodReference, history]);

  const dispatchSetMoodAction = async (rate: number) => {
    await dispatch(setUserMood(rate));
  };

  const setMood = async (rate: number) => {
    setOpen(false);
    await dispatchSetMoodAction(rate).then(() => {
      setMoodDialogShown();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="mood-dialog-title"
      aria-describedby="mood-dialog-description"
    >
      <DialogTitle id="mood-dialog-title">
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={() => setOpen(false)}
        >
          <img src={iconClose} alt="" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.moodContent} id="mood-dialog-description">
          <div className={classes.moodTitle}>{t("mood.dialog.title")}</div>
          <div className={classes.moodList}>
            {moods.map((mood, index) => {
              return (
                <Button
                  style={{ flex: 1, minWidth: "unset" }}
                  key={index}
                  onClick={() => setMood(index + 1)}
                >
                  <img src={mood} alt="" />
                </Button>
              );
            })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(MoodRatingDialog);
