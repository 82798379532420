import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useDialogStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      content: {
        margin: theme.spacing(3, 0),
        "& img, & iframe": {
          width: "100%",
          height: "auto",
          borderRadius: "10px",
        },
      },
      moodContent: {
        textAlign: "center",
        width: "100%",
      },
      moodTitle: {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "32px",
        color: "#000",
        margin: theme.spacing(3, 0),
      },
      moodList: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
      },
    }),
  { index: 1 }
);
