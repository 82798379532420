import { AxiosResponse } from "axios";

export enum SetMood {
  REQUEST = "SET_MOOD_REQUEST",
  SUCCESS = "SET_MOOD_SUCCESS",
  FAILED = "SET_MOOD_FAILED",
}

export type TSetMoodAction =
  | {
      type: SetMood.REQUEST;
    }
  | {
      type: SetMood.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: SetMood.FAILED;
      payload: AxiosResponse<any>;
    };
