import * as React from "react";
import logo_error from "../assets/images/logo_error.svg";
import { Grid } from "@material-ui/core";
import { routes } from "../Routes";
import { useTranslation } from "react-i18next";

interface IErrorPageProps {
  message: string;
}

const ErrorPage: React.FC<IErrorPageProps> = ({ message }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid
        style={{ margin: "50px 0", textAlign: "center" }}
        container
        direction={"column"}
        alignContent={"center"}
      >
        <img src={logo_error} alt="logo" style={{ margin: "20px 0 0" }} />
        <p style={{ margin: "20px 0 0" }}>{message}</p>
        <a style={{ margin: "20px 0 0" }} href={routes.home}>
          {t("homepage.link")}
        </a>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
