import { Reducer } from "redux";
import {
  initialAuthState,
  LoginUser,
  TAuthActions,
  TAuthState,
} from "../../types/Auth";
import { setAccessToken, setLastLogin } from "../../helpers/Misc";

export const authReducer: Reducer<TAuthState, TAuthActions> = (
  state = initialAuthState,
  action
) => {
  switch (action.type) {
    case LoginUser.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LoginUser.SUCCESS:
      setAccessToken(action.payload.data.token);
      setLastLogin();
      return {
        ...state,
        loading: false,
        token: action.payload.data.token,
      };

    case LoginUser.FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
