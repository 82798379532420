import {
  CloseMotivationalDialog,
  CopyNoteText,
  DeleteMotivatingImage,
  DeleteMyNote,
  GetCurrentUser,
  GetMotivatingImages,
  GetMyNotes,
  GetRandomPopup,
  SaveMyNote,
  TApiUserEntity,
  TCloseMotivationalDialogAction,
  TCopyNoteTextAction,
  TDeleteMotivatingImageAction,
  TDeleteMyNoteAction,
  TGetCurrentUserAction,
  TGetMotivatingImagesAction,
  TGetMyNotesAction,
  TGetRandomPopupAction,
  TSaveMyNoteAction,
  TUpdateUserProfileAction,
  TUploadMotivationalImageAction,
  UpdateUserProfile,
  UploadMotivationalImage,
} from "../../types/User";
import { apiCall } from "./BaseAction";
import { SetMood, TSetMoodAction } from "../../types/Mood";
import { Dispatch } from "redux";

export const getCurrentUser = () => {
  return apiCall<TGetCurrentUserAction, null, TApiUserEntity>(
    GetCurrentUser,
    "GET",
    "/auth/me",
    true
  );
};

export const setUserMood = (rate: number) => {
  return apiCall<TSetMoodAction>(SetMood, "POST", "/add-mood-rating", true, {
    rate,
  });
};

export const getRandomPopup = () => {
  return apiCall<TGetRandomPopupAction>(GetRandomPopup, "GET", "/popup", true);
};

export type TSaveMyNoteRequest = {
  text: string;
  parent_url: string;
};
export const saveMyNote = (data: TSaveMyNoteRequest) => {
  return apiCall<TSaveMyNoteAction>(SaveMyNote, "POST", "/add-note", true, {
    ...data,
  });
};

export interface IUpdateUserProfileRequest {
  last_gambled: string;
  money_spend_period: string;
  money_spend_per_period: number;
  crisis_person_name: string;
  crisis_person_phone: string;
}
export const updateUserProfile = (data: IUpdateUserProfileRequest) => {
  return apiCall<TUpdateUserProfileAction, IUpdateUserProfileRequest>(
    UpdateUserProfile,
    "POST",
    "/update-user",
    true,
    { ...data }
  );
};

export const uploadMotivationalImage = (image: string) => {
  return apiCall<TUploadMotivationalImageAction>(
    UploadMotivationalImage,
    "POST",
    "/add-motivating-image",
    true,
    { image }
  );
};

export const getMotivatingImages = () => {
  return apiCall<TGetMotivatingImagesAction>(
    GetMotivatingImages,
    "GET",
    "/motivating-images",
    true
  );
};
export const deleteMotivatingImage = (image_id: number) => {
  return apiCall<TDeleteMotivatingImageAction>(
    DeleteMotivatingImage,
    "DELETE",
    "/delete-motivating-image",
    true,
    { image_id }
  );
};

export const getMyNotes = () => {
  return apiCall<TGetMyNotesAction>(GetMyNotes, "GET", "/user-notes", true);
};
export const deleteMyNote = (note_id: number) => {
  return apiCall<TDeleteMyNoteAction>(
    DeleteMyNote,
    "DELETE",
    "/delete-note",
    true,
    { note_id }
  );
};

export const closeMotivationalDialog = (payload: boolean) => {
  return (dispatch: Dispatch<TCloseMotivationalDialogAction>) => {
    dispatch({
      type: CloseMotivationalDialog.SET,
      payload,
    });
  };
};

export const copyNoteText = (payload: string) => {
  return (dispatch: Dispatch<TCopyNoteTextAction>) => {
    dispatch({
      type: CopyNoteText.SET,
      payload,
    });
  };
};
