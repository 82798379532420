import * as React from "react";
import { Button } from "@material-ui/core";
import { useNavbarStyles } from "../Navbar.styles";

interface IPhoneLinkProps {
  url: string;
  closeMenuOnClick?: () => void;
  icon: any;
  title: string;
}

const PhoneLink: React.FC<IPhoneLinkProps> = (props): JSX.Element => {
  const classes = useNavbarStyles({});
  const { closeMenuOnClick, ...rest } = props;

  return (
    <Button
      {...rest}
      className={classes.menuItem}
      onClick={props.closeMenuOnClick}
      component={"a"}
      href={props.url}
    >
      {props.title}
      <img src={props.icon} alt="arrow" />
    </Button>
  );
};

export default PhoneLink;
