import { AxiosResponse } from "axios";

export interface TApiContentPageEntity {
  title: string;
  reference: string;
  url: string;
  content: string;
}

export interface TContentState {
  readonly activePage?: TApiContentPageEntity;
  readonly loading?: boolean;
}

export const initialContentState: TContentState = {
  activePage: undefined,
};

export enum GetStaticPageContent {
  REQUEST = "GET_STATIC_PAGE_CONTENT_REQUEST",
  SUCCESS = "GET_STATIC_PAGE_CONTENT_SUCCESS",
  FAILED = "GET_STATIC_PAGE_CONTENT_FAILED",
}

export type TGetStaticPageContentAction =
  | {
      type: GetStaticPageContent.REQUEST;
    }
  | {
      type: GetStaticPageContent.SUCCESS;
      payload: AxiosResponse<TApiContentPageEntity>;
    }
  | {
      type: GetStaticPageContent.FAILED;
      payload: AxiosResponse<TApiContentPageEntity>;
    };

export type TContentActions = TGetStaticPageContentAction;
