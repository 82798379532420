import React, { Suspense } from "react";
import { Route, RouteProps } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";
import Logo from "../../components/Logo";

interface IAuthRouteProps extends RouteProps {
  component: React.ComponentClass | React.FC;
}

const AuthRoute: React.FC<IAuthRouteProps & RouteComponentProps> = (
  props,
  ...rest
) => {
  return (
    <Suspense fallback={""}>
      <Logo />
      <Route component={props.component} exact path={props.path} {...rest} />
    </Suspense>
  );
};
export default withRouter(AuthRoute);
