import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { INavbarContentProps } from "./NavbarContent";

export const useNavbarStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "space-between",
        margin: "50px 0",
      },
      menuArea: {
        display: "flex",
        alignItems: "center",
      },
      profileButton: {
        padding: "10px",
      },
      contentRoot: {
        position: (props: INavbarContentProps) =>
          props.relativeContent ? "unset" : "fixed",
        top: "135px",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#f3f3f3",
        zIndex: 3,
        overflow: "auto",
      },
      menuItem: {
        backgroundColor: "white",
        padding: "5px 20px",
        boxShadow: "8px 8px 8px rgba(0, 0, 0, 0.02)",
        borderRadius: "10px",
        width: "100%",
        marginBottom: "15px",
        fontStyle: "normal",
        fontWeight: "normal",
        display: "flex",
        textAlign: "left",
        justifyContent: "space-between",
        "&:hover, &:focus, &:active": {
          backgroundColor: "white",
        },
        "& $span": {
          fontSize: "24px",
          lineHeight: "26px",
        },
        "& img": {
          width: "40px",
          height: "40px",
        },
      },
    }),
  { index: 1 }
);
