import { AxiosResponse, AxiosError } from "axios";
import { MiddlewareAPI, Dispatch, Middleware, AnyAction } from "redux";
import { errorAction } from "../actions/BaseAction";
import i18n from "../../config/i18next";
import { routes } from "../../Routes";
import { removeAuthInfoFromLocalStorage } from "../../helpers/Misc";

interface IAction {
  type: any;
  payload: AxiosResponse<any> & AxiosError<any>;
}

export const ErrorHandler: Middleware = (state: MiddlewareAPI<any>) => (
  next: Dispatch<AnyAction>
) => (action: IAction) => {
  let ErrorCode;
  try {
    if (action.type.includes("_FAILED")) {
      ErrorCode = action.payload.response?.status;
      throw new Error(i18n.t("Error occurred"));
    }
  } catch (e) {
    switch (ErrorCode) {
      case 401:
        removeAuthInfoFromLocalStorage();
        return window.location.replace(
          `${routes.loginPage}?p=${window.location.pathname}`
        );

      case 403:
        return state.dispatch(
          errorAction({ message: i18n.t("Forbidden"), code: 403 })
        );

      case 404:
        return state.dispatch(
          errorAction({ message: i18n.t("Not found"), code: 404 })
        );

      case 500:
        return state.dispatch(
          errorAction({ message: i18n.t("Internal server error"), code: 500 })
        );
    }
  }

  return next(action);
};
