import i18n from "i18next";
import XHR from "i18next-xhr-backend";

const language = i18n.language || "da";

const backendOptions = {
  type: "backend",
  crossDomain: true,
  allowMultiLoading: true,
  loadPath: `${process.env.REACT_APP_BASE_API_URL}/localization/translations/{{lng}}`,
  addPath: `${process.env.REACT_APP_BASE_API_URL}/localization/add-translation-keys`,
  customHeaders: {
    Accept: "application/json",
  },
};

const options: any = {
  initImmediate: false,
  debug: false,
  lng: language,
  fallbackLng: ["da"],
  preload: ["da"],
  ns: ["translation"],
  defaultNS: "translation",
  keySeparator: false,
  saveMissing: true,

  react: {
    wait: true,
    useSuspense: false,
  },
};

options["backend"] = backendOptions;

i18n.use(XHR).init(options);

export default i18n;
