import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import iconClose from "../../assets/images/icon_close.svg";
import { useDialogStyles } from "./Dialog.styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  copyNoteText,
  saveMyNote,
  TSaveMyNoteRequest,
} from "../../store/actions/UserActions";

interface ISaveNoteDialogProps {
  open: boolean;
  noteText: string;
}

const SaveNoteDialog: React.FC<ISaveNoteDialogProps> = (props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const classes = useDialogStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const cancelNoteSave = () => {
    dispatch(copyNoteText(""));
    setOpen(false);
  };

  const submitNoteSave = () => {
    dispatchSaveMyNoteAction({
      text: props.noteText,
      parent_url: window.location.pathname,
    });
  };

  const dispatchSaveMyNoteAction = async (data: TSaveMyNoteRequest) => {
    await dispatch(saveMyNote(data));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="note-dialog-title"
        aria-describedby="note-dialog-description"
      >
        <DialogTitle id="note-dialog-title">
          {t("save.my.note.dialog.title")}
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={() => cancelNoteSave()}
          >
            <img src={iconClose} alt="" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div
            dangerouslySetInnerHTML={{
              __html: props.noteText,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => cancelNoteSave()}>
            <span>{t("cancel.note.save")}</span>
          </Button>
          <Button
            color="primary"
            onClick={() => submitNoteSave()}
            variant={"contained"}
            autoFocus
          >
            {t("save.as.my.note")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SaveNoteDialog;
