import { Reducer } from "redux";
import {
  initialErrorState,
  SetAppError,
  TErrorActions,
  TErrorState,
} from "../../types/Error";

export const errorReducer: Reducer<TErrorState, TErrorActions> = (
  state = initialErrorState,
  action
) => {
  switch (action.type) {
    case SetAppError.SUCCESS:
      return {
        code: action.payload.code,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
