import {
  CloseMotivationalDialog,
  CopyNoteText,
  GetCurrentUser,
  GetMotivatingImages,
  GetMyNotes,
  GetRandomPopup,
  initialUserState,
  SaveMyNote,
  SetMood,
  TUserActions,
  TUserState,
  UpdateUserProfile,
} from "../../types/User";
import { Reducer } from "redux";
import { sortBy } from "lodash";

export const usersReducer: Reducer<TUserState, TUserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case GetCurrentUser.REQUEST:
    case UpdateUserProfile.REQUEST:
    case SetMood.REQUEST:
    case GetRandomPopup.REQUEST:
    case SaveMyNote.REQUEST:
    case GetMotivatingImages.REQUEST:
    case GetMyNotes.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetMyNotes.FAILED:
    case GetMotivatingImages.FAILED:
    case SaveMyNote.FAILED:
    case GetRandomPopup.FAILED:
    case SetMood.FAILED:
    case UpdateUserProfile.FAILED:
    case GetCurrentUser.FAILED:
      return {
        ...state,
        loading: false,
      };

    case GetCurrentUser.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.user,
      };

    case UpdateUserProfile.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };

    case SetMood.SUCCESS:
      return {
        ...state,
        loading: false,
        badMoodReference: action.payload.data["redirect-reference"],
      };

    case GetRandomPopup.SUCCESS:
      return {
        ...state,
        activeRandomPopup: action.payload.data,
      };

    case CloseMotivationalDialog.SET:
      return {
        ...state,
        activeRandomPopup: undefined,
      };

    case CopyNoteText.SET:
      return {
        ...state,
        tempNoteText: action.payload,
        noteSaved: false,
      };

    case SaveMyNote.SUCCESS:
      return {
        ...state,
        loading: false,
        tempNoteText: undefined,
        noteSaved: true,
      };

    case GetMotivatingImages.SUCCESS:
      return {
        ...state,
        loading: false,
        motivatingImages: sortBy(action.payload.data, (i) => i.user_id),
      };

    case GetMyNotes.SUCCESS:
      return {
        ...state,
        loading: false,
        myNotes: sortBy(action.payload.data, (i) => i.user_id),
      };

    default:
      return state;
  }
};
