import moment from "moment";

export const getAccessToken = () => {
  return localStorage.getItem("token");
};

export const removeAuthInfoFromLocalStorage = () => {
  localStorage.removeItem("token");
};

export const setAccessToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const getLastMoodDialogDate = () => {
  const date = localStorage.getItem("lastMoodDialogShown");

  return JSON.parse(date as string);
};

export const shouldShowMoodDialog = () => {
  const now = moment(new Date());
  const lastShow = moment(getLastMoodDialogDate() || now);

  return (
    moment.duration(now.diff(lastShow)).asDays() >= 1 ||
    !getLastMoodDialogDate()
  );
};

export const setMoodDialogShown = () => {
  const now = new Date();
  const date = moment(now);

  return localStorage.setItem("lastMoodDialogShown", JSON.stringify(date));
};

export const setMotivationalDialogShown = () => {
  const now = new Date();
  const date = moment(now);

  return localStorage.setItem(
    "lastMotivationalDialogShown",
    JSON.stringify(date)
  );
};

export const getLastMotivationalDialogDate = () => {
  const date = localStorage.getItem("lastMotivationalDialogShown");

  return JSON.parse(date as string);
};

export const getLastLoginDate = () => {
  const date = localStorage.getItem("lastLogin");

  return JSON.parse(date as string);
};

export const setLastLogin = () => {
  const now = new Date();

  return localStorage.setItem("lastLogin", JSON.stringify(moment(now)));
};

export const shouldShowMotivationalDialog = () => {
  const now = moment(new Date());
  const lastShow = moment(getLastMotivationalDialogDate() || now);
  const loginDate = moment(getLastLoginDate() || now);

  if (moment.duration(now.diff(loginDate)).asMinutes() < 2) {
    return false;
  }

  return (
    moment.duration(now.diff(lastShow)).asDays() >= 1 ||
    !getLastMotivationalDialogDate()
  );
};
